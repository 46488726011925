





















import { Component, Mixins } from 'vue-property-decorator'
import { Account } from '@/api'
import { UserModule } from '@/store/modules'
import { Toast } from '@/mixins'

@Component
export default class Enable2FAModal extends Mixins(Toast) {
  @UserModule.Action getUser

  showModal = false
  code = ''
  error = ''

  get isCodeValid() {
    return this.code && (this.code.match(/^(\d{6})?$/) || this.code.match(/^(\w{8})?$/))
  }

  async disable2FA() {
    try {
      const res = await Account.disable2FA(this.code)

      if (res) {
        this.error = res
        throw new Error()
      }

      const successMsg = this.$t('2FA_DISABLE_SUCCESS_MSG') as string

      this.showSuccessToast({
        title: 'Successfully disabled!',
        message: successMsg
      })

      this.code = ''
      await this.getUser()
      this.showModal = false
    } catch(e) {
      console.error('Cannot disable 2FA', e)
    }
  }
}
